<template>
  <table class="customtable w-100">
    <thead>
      <tr>
        <ZedvanceTableTh
          v-for="(item, index) in columns"
          v-bind:key="index"
          v-bind:title="item.title"
        />
      </tr>
    </thead>
    <tbody></tbody>
  </table>
</template>

<script>
export default {
  name: "ZedvanceTable",
  data: function () {
    return {
      activeDropdown: null,
    };
  },
  props: {
    data: Array,
    columns: Array,
    cell: Function,
  },
  methods: {
    setActiveDropdown: function (id) {
      this.activeDropdown = id;
    },
  },
};
</script>
