import {
  GET_ACCESS_RIGHT,
  GET_ALL_ROLE,
  GET_ALL_ROLE_EXPORTS,
  GET_ALL_USER,
  GET_ALL_USER_EXPORTS,
  GET_BRANCHES,
  GET_PERMISSION,
  GET_ROLE,
  GET_ROLES,
  GET_TRANSACTION_TYPE,
  GET_TYPE,
  GET_USER,
  LOADING_BRANCHES,
  LOADING_PERMISSION,
  LOADING_ROLE,
  LOADING_TRANSACTION_TYPE,
  LOADING_USER,
  SET_ACCESS_RIGHT,
  SET_ALL_ROLE,
  SET_ALL_ROLE_EXPORTS,
  SET_ALL_USER,
  SET_ALL_USER_EXPORTS,
  SET_BRANCHES,
  SET_PERMISSION,
  SET_ROLE,
  SET_ROLES,
  SET_TRANSACTION_TYPE,
  SET_TYPE,
  SET_USER,
  SET_USER_ERROR_CODE,
  SET_USER_ERROR_MESSAGE,
} from "./types";

import ApiService from "@/core/services/access.service";
import GeneralApiService from "@/core/services/general.service";

const state = {
  allRole: [],
  allRoles: [],
  allUser: [],
  allExports: [],
  allRoleExports: [],
  permission: [],
  transactionType: [],
  branches: [],
  role: {},
  user: {},
  getType: [],
  accessRight: [],
  saving: false,
  loadingPermission: false,
  loadBranches: false,
  loadingTransactionType: false,
  loadingRole: false,
  loadingUser: false,
  errorCode: "",
  errorMessage: "",
};

const mutations = {
  [SET_ALL_ROLE](state, payload) {
    state.allRole = payload;
  },
  [SET_ROLE](state, payload) {
    state.role = payload;
  },
  [SET_PERMISSION](state, payload) {
    state.permission = payload;
  },
  [SET_BRANCHES](state, payload) {
    state.branches = payload;
  },
  [SET_TRANSACTION_TYPE](state, payload) {
    state.transactionType = payload;
  },
  [SET_ALL_USER](state, payload) {
    state.allUser = payload;
  },
  [SET_ALL_USER_EXPORTS](state, payload) {
    state.allExports = payload;
  },
  [SET_ALL_ROLE_EXPORTS](state, payload) {
    state.allRoleExports = payload;
  },
  [SET_USER](state, payload) {
    state.user = payload;
  },
  [SET_ROLE](state, payload) {
    state.role = payload;
  },
  [SET_ROLES](state, payload) {
    state.AllRoles = payload;
  },
  [SET_ACCESS_RIGHT](state, payload) {
    state.accessRight = payload;
  },
  [SET_TYPE](state, payload) {
    state.getType = payload;
  },
  [LOADING_PERMISSION](state, payload) {
    state.loadingPermission = payload;
  },
  [LOADING_BRANCHES](state, payload) {
    state.loadBranches = payload;
  },
  [LOADING_TRANSACTION_TYPE](state, payload) {
    state.loadingTransactionType = payload;
  },
  [LOADING_ROLE](state, payload) {
    state.loadingRole = payload;
  },
  [LOADING_USER](state, payload) {
    state.loadingUser = payload;
  },
  [SET_USER_ERROR_MESSAGE](state, payload) {
    state.errorMessage = payload;
  },
  [SET_USER_ERROR_CODE](state, payload) {
    state.errorCode = payload;
  },
};

const actions = {
  [GET_TYPE]({ commit }) {
    commit(LOADING_ROLE, true);

    ApiService.get(`RoleManagement/GetType`)
      .then((res) => {
        commit(LOADING_ROLE, false);
        commit(SET_TYPE, res.data);
      })
      .catch(() => {
        commit(LOADING_ROLE, false);
        commit(SET_TYPE, null);
      });
  },
  [GET_ACCESS_RIGHT]({ commit }) {
    commit(LOADING_ROLE, true);

    ApiService.get(`RoleManagement/GetAccessRight`)
      .then((res) => {
        commit(LOADING_ROLE, false);
        commit(SET_ACCESS_RIGHT, res.data);
      })
      .catch(() => {
        commit(LOADING_ROLE, false);
        commit(SET_ACCESS_RIGHT, null);
      });
  },
  [GET_PERMISSION]({ commit }) {
    commit(LOADING_PERMISSION, true);

    ApiService.get(`Permission/GetPermissions`)
      .then((res) => {
        commit(LOADING_PERMISSION, false);
        commit(SET_PERMISSION, res.data);
      })
      .catch(() => {
        commit(LOADING_PERMISSION, false);
        commit(SET_PERMISSION, null);
      });
  },
  [GET_ROLE]({ commit }, key) {
    commit(LOADING_ROLE, true);

    ApiService.get(`RoleManagement/GetRoleByRoleKey/roleKey${key}`)
      .then((res) => {
        commit(LOADING_ROLE, false);
        commit(SET_ROLE, res.data);
      })
      .catch(() => {
        commit(LOADING_ROLE, false);
        commit(SET_ROLE, null);
      });
  },
  [GET_ALL_ROLE]({ commit }, payload) {
    commit(LOADING_ROLE, true);
    if (payload.isFromFilter) {
      return new Promise((resolve, reject) => {
        GeneralApiService.post(`GeneralFilter/ApplyFilter`, payload)
          .then((res) => {
            commit(SET_ALL_ROLE, res.data);
            commit(SET_USER_ERROR_CODE, "");
            commit(LOADING_ROLE, false);
            resolve(true);
          })
          .catch((e) => {
            commit(SET_ALL_ROLE, null);
            commit(SET_USER_ERROR_MESSAGE, e.response.message);
            commit(SET_USER_ERROR_CODE, e.response.status);
            commit(LOADING_ROLE, false);
            reject(e);
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        ApiService.get(
          `RoleManagement/GetRoles/pageNumber/${payload.pageIndex}/pageSize/${payload.pageSize}?searchName=${payload.searchRoleName}`
        )
          .then((res) => {
            commit(SET_ALL_ROLE, res.data);
            commit(SET_ALL_ROLE_EXPORTS, res.data);
            commit(SET_USER_ERROR_CODE, "");
            commit(LOADING_ROLE, false);
            resolve(true);
          })
          .catch((e) => {
            commit(SET_ALL_ROLE, null);
            commit(SET_USER_ERROR_MESSAGE, e);
            commit(SET_USER_ERROR_CODE, e);
            commit(LOADING_ROLE, false);
            reject(e);
          });
      });
    }
  },
  [GET_ROLES]({ commit }) {
    commit(LOADING_ROLE, true);
    ApiService.get(`RoleManagement/GetAllRoles`)
      .then((res) => {
        commit(LOADING_ROLE, false);
        commit(SET_ROLES, res.data);
        commit(SET_USER_ERROR_CODE, "");
      })
      .catch((e) => {
        commit(LOADING_ROLE, false);
        commit(SET_ROLES, null);
        commit(SET_USER_ERROR_MESSAGE, e);
        commit(SET_USER_ERROR_CODE, e);
      });
  },
  [GET_USER]({ commit }, key) {
    commit(LOADING_USER, true);
    commit(SET_USER, null);
    ApiService.get(`ProleanUser/GetUser/${key}/reference`)
      .then((res) => {
        commit(LOADING_USER, false);
        commit(SET_USER, res.data);
      })
      .catch(() => {
        commit(LOADING_USER, false);
        commit(SET_USER, null);
      });
  },
  [GET_ALL_USER]({ commit }, payload) {
    commit(LOADING_USER, true);
    if (payload.isFromFilter) {
      GeneralApiService.post(`GeneralFilter/ApplyFilter`, payload)
        .then((res) => {
          commit(LOADING_USER, false);
          commit(SET_ALL_USER, res.data);
          commit(SET_USER_ERROR_CODE, "");
        })
        .catch((e) => {
          commit(LOADING_USER, false);
          commit(SET_ALL_USER, null);
          commit(SET_USER_ERROR_MESSAGE, e.response.message);
          commit(SET_USER_ERROR_CODE, e.response.status);
        });
    } else {
      ApiService.post(`ProleanUser/GetUsers`, payload)
        .then((res) => {
          commit(LOADING_USER, false);
          commit(SET_ALL_USER_EXPORTS, res.data);
          commit(SET_ALL_USER, res.data);
          commit(SET_USER_ERROR_CODE, "");
        })
        .catch((e) => {
          commit(LOADING_USER, false);
          commit(SET_ALL_USER, null);
          commit(SET_USER_ERROR_MESSAGE, e.response.message);
          commit(SET_USER_ERROR_CODE, e.response.status);
        });
    }
    // ApiService.post(
    //   `${ApiService.getUrl()}/ProleanUser/GetUsers`, payload
    // )
    //   .then((res) => {
    //     commit(LOADING_USER, false);
    //     commit(SET_ALL_USER, res.data);
    //   })
    //   .catch(() => {
    //     commit(LOADING_USER, false);
    //     commit(SET_ALL_USER, null);
    //   });
  },
  [GET_ALL_USER_EXPORTS]({ commit }, payload) {
    commit(LOADING_USER, true);
    GeneralApiService.post(`GeneralFilter/ApplyFilter`, payload)
      .then((res) => {
        commit(LOADING_USER, false);
        commit(SET_ALL_USER_EXPORTS, res.data);
        commit(SET_USER_ERROR_CODE, "");
      })
      .catch((e) => {
        commit(LOADING_USER, false);
        commit(SET_USER_ERROR_MESSAGE, e.response.message);
        commit(SET_USER_ERROR_CODE, e.response.status);
      });
  },
  [GET_ALL_ROLE_EXPORTS]({ commit }, payload) {
    commit(LOADING_USER, true);
    GeneralApiService.post(`GeneralFilter/ApplyFilter`, payload)
      .then((res) => {
        commit(LOADING_ROLE, false);
        commit(SET_ALL_ROLE_EXPORTS, res.data);
        commit(SET_USER_ERROR_CODE, "");
      })
      .catch((e) => {
        commit(LOADING_ROLE, false);
        commit(SET_USER_ERROR_MESSAGE, e.response.message);
        commit(SET_USER_ERROR_CODE, e.response.status);
      });
  },
  [GET_BRANCHES]({ commit }, data) {
    commit(LOADING_BRANCHES, true);
    GeneralApiService.post(`Branch/Branches`, data)
      .then((res) => {
        commit(LOADING_BRANCHES, false);
        commit(SET_BRANCHES, res.data);
      })
      .catch(() => {
        commit(LOADING_BRANCHES, false);
        commit(SET_BRANCHES, null);
      });
  },
  [GET_TRANSACTION_TYPE]({ commit }) {
    commit(LOADING_TRANSACTION_TYPE, true);
    ApiService.get(`ProleanUser/GetTransactionLimitType`)
      .then((res) => {
        commit(LOADING_TRANSACTION_TYPE, false);
        commit(SET_TRANSACTION_TYPE, res.data);
      })
      .catch(() => {
        commit(LOADING_TRANSACTION_TYPE, false);
        commit(SET_TRANSACTION_TYPE, null);
      });
  },
};

const getters = {
  loadRoles(state) {
    return state.allRole;
  },
};
export default {
  state,
  actions,
  mutations,
  getters,
  namespace: true,
};
