import {
  GET_ALL_LOAN_PRODUCTS,
  GET_LOAN_ACCOUNTS,
  GET_LOAN_ACCOUNTS_FOR_PENDING,
  GET_LOAN_ACCOUNT_DETAILS,
  GET_LOAN_EXPORTS,
  GET_LOAN_PRODUCTS,
  LOADING_ALL_LOAN_PRODUCTS,
  LOADING_LOAN_ACCOUNTS,
  LOADING_LOAN_ACCOUNT_DETAILS,
  LOADING_LOAN_PRODUCTS,
  SAVE_LOAN_PRODUCTS,
  SAVING_LOAN_PRODUCTS,
  SET_ERROR_CODE,
  SET_LOAN_ACCOUNTS,
  SET_LOAN_ACCOUNT_DETAILS,
  SET_LOAN_ACCOUNT_DETAILS_ERROR,
  SET_LOAN_EXPORTS,
  SET_LOAN_PRODUCTS,
  SET_LOAN_PRODUCTS_ALL,
} from "./types";

import LoanApiService from "@/core/services/api.loan.service";
import GeneralApiService from "@/core/services/general.service";
const product_enums = require("./products_enums");
const state = {
  products: [],
  accounts: [],
  loanExports: [],
  account_details: null,
  loading_account_details: null,
  products_all: [],
  saving: false,
  loading_all: false,
  loading_accounts: false,
  enums: product_enums,
  error: "",
  errorCode: "",
};

const mutations = {
  [SET_LOAN_PRODUCTS](state, payload) {
    state.products = payload;
  },
  [SET_LOAN_PRODUCTS_ALL](state, payload) {
    state.products_all = payload;
  },
  [SET_LOAN_ACCOUNTS](state, payload) {
    state.accounts = payload;
  },
  [SET_LOAN_EXPORTS](state, payload) {
    state.loanExports = payload;
  },
  [LOADING_LOAN_ACCOUNTS](state, payload) {
    state.loading_accounts = payload;
  },
  [SAVING_LOAN_PRODUCTS](state, payload) {
    state.saving = payload;
  },
  [LOADING_LOAN_PRODUCTS](state, payload) {
    state.loading = payload;
  },
  [LOADING_ALL_LOAN_PRODUCTS](state, payload) {
    state.loading_all = payload;
  },
  [SET_LOAN_ACCOUNT_DETAILS](state, payload) {
    state.account_details = payload;
  },
  [SET_LOAN_ACCOUNT_DETAILS_ERROR](state, payload) {
    state.error = payload;
  },
  [SET_ERROR_CODE](state, payload) {
    state.errorCode = payload;
  },
  [LOADING_LOAN_ACCOUNT_DETAILS](state, payload) {
    state.loading_account_details = payload;
  },
};

const actions = {
  [GET_LOAN_PRODUCTS](
    { commit },
    { page, size, activestatus, category, sortColumn, sort, productname }
  ) {
    commit(LOADING_LOAN_PRODUCTS, true);
    return new Promise((resolve, reject) => {
      LoanApiService.get(
        `LoanProduct/getloanproducts?pageindex=${page}&pagesize=${size}&includeDeleted=false&activestatus=${activestatus}&category=${
          category === null ? "" : category
        }&sortColumn=${
          sortColumn === null ? "" : sortColumn
        }&sortDesending=${sort}&productname=${productname}
        `
      )
        .then((response) => {
          commit(LOADING_LOAN_PRODUCTS, false);
          commit(SET_LOAN_PRODUCTS, response.data);
          resolve(true);
        })
        .catch(() => {
          commit(LOADING_LOAN_PRODUCTS, false);
          commit(SET_LOAN_PRODUCTS, null);
          reject();
        });
    });
  },

  // [GET_LOAN_PRODUCTS]({ commit }, p) {
  //   commit(LOADING_LOAN_PRODUCTS, true);
  //   LoanApiService.get(
  //     `LoanProduct/getloanproducts?pageindex=${p.page}&pagesize=${
  //       p.size
  //     }&includeDeleted=false&activestatus=${p.activestatus}&category=${
  //       p.category === null ? "" : p.category
  //     }`
  //   )
  //     .then((res) => {
  //       commit(LOADING_LOAN_PRODUCTS, false);
  //       commit(SET_LOAN_PRODUCTS, res.data);
  //     })
  //     .catch(() => {
  //       commit(LOADING_LOAN_PRODUCTS, false);
  //       commit(SET_LOAN_PRODUCTS, null);
  //     });
  // },
  // [GET_LOAN_ACCOUNTS]({ commit }, { page, size, sortColumn, sort }) {
  //   commit(LOADING_LOAN_ACCOUNTS, true);
  //   LoanApiService.get(
  //     `LoanAccount/get-loan-accounts?IncludeDeleted=false&PageIndex=${page}&PageSize=${size}&SortDesending=${sort}&SortColumn=${sortColumn}`
  //   )
  //     .then((res) => {
  //       commit(LOADING_LOAN_ACCOUNTS, false);
  //       commit(SET_LOAN_ACCOUNTS, res.data);
  //     })
  //     .catch(() => {
  //       commit(LOADING_LOAN_ACCOUNTS, false);
  //       commit(SET_LOAN_PRODUCTS_ALL, null);
  //     });
  // },
  [GET_LOAN_ACCOUNTS_FOR_PENDING]({ commit }, payload) {
    commit(LOADING_LOAN_ACCOUNTS, true);
    if (payload.isFromFilter) {
      GeneralApiService.post("GeneralFilter/ApplyFilter", payload)
        .then((res) => {
          commit(LOADING_LOAN_ACCOUNTS, false);
          commit(SET_LOAN_ACCOUNTS, res.data);
          commit(SET_ERROR_CODE, "");
          commit(SET_LOAN_ACCOUNT_DETAILS_ERROR, "");
        })
        .catch((e) => {
          commit(LOADING_LOAN_ACCOUNTS, false);
          commit(SET_LOAN_PRODUCTS_ALL, null);
          commit(SET_ERROR_CODE, e.response.status);
          commit(
            SET_LOAN_ACCOUNT_DETAILS_ERROR,
            "You are trying to access an account that you dont have access to. Kindly contact admin"
          );
        });
    } else {
      LoanApiService.post(
        "LoanAccountFilter/GetFilteredLoanAccountWithStatus",
        payload
      )
        .then((res) => {
          commit(LOADING_LOAN_ACCOUNTS, false);
          commit(SET_LOAN_ACCOUNTS, res.data);
          commit(SET_LOAN_EXPORTS, res.data);
          commit(SET_ERROR_CODE, "");
          commit(SET_LOAN_ACCOUNT_DETAILS_ERROR, "");
        })
        .catch((e) => {
          commit(LOADING_LOAN_ACCOUNTS, false);
          commit(SET_LOAN_PRODUCTS_ALL, null);
          commit(SET_ERROR_CODE, e.response.status);
          commit(
            SET_LOAN_ACCOUNT_DETAILS_ERROR,
            "You are trying to access an account that you dont have access to. Kindly contact admin"
          );
        });
    }
  },
  [GET_LOAN_ACCOUNTS]({ commit }, payload) {
    commit(LOADING_LOAN_ACCOUNTS, true);
    if (payload.isFromFilter) {
      GeneralApiService.post("GeneralFilter/ApplyFilter", payload)
        .then((res) => {
          commit(LOADING_LOAN_ACCOUNTS, false);
          commit(SET_LOAN_ACCOUNTS, res.data);
          commit(SET_ERROR_CODE, "");
          commit(SET_LOAN_ACCOUNT_DETAILS_ERROR, "");
        })
        .catch((e) => {
          commit(LOADING_LOAN_ACCOUNTS, false);
          commit(SET_LOAN_PRODUCTS_ALL, null);
          commit(SET_ERROR_CODE, e.response.status);
          commit(
            SET_LOAN_ACCOUNT_DETAILS_ERROR,
            "You are trying to access an account that you dont have access to. Kindly contact admin"
          );
        });
    } else {
      LoanApiService.post("LoanAccountFilter/GetFilteredLoanAccount", payload)
        .then((res) => {
          commit(LOADING_LOAN_ACCOUNTS, false);
          commit(SET_LOAN_ACCOUNTS, res.data);
          commit(SET_LOAN_EXPORTS, res.data);
          commit(SET_ERROR_CODE, "");
          commit(SET_LOAN_ACCOUNT_DETAILS_ERROR, "");
        })
        .catch((e) => {
          commit(LOADING_LOAN_ACCOUNTS, false);
          commit(SET_LOAN_PRODUCTS_ALL, null);
          commit(SET_ERROR_CODE, e.response.status);
          commit(
            SET_LOAN_ACCOUNT_DETAILS_ERROR,
            "You are trying to access an account that you dont have access to. Kindly contact admin"
          );
        });
    }
  },
  [GET_LOAN_EXPORTS]({ commit }, payload) {
    commit(LOADING_LOAN_ACCOUNTS, true);
    GeneralApiService.post("GeneralFilter/ApplyFilter", payload)
      .then((res) => {
        commit(LOADING_LOAN_ACCOUNTS, false);
        commit(SET_LOAN_EXPORTS, res.data);
        commit(SET_ERROR_CODE, "");
        commit(SET_LOAN_ACCOUNT_DETAILS_ERROR, "");
      })
      .catch((e) => {
        commit(LOADING_LOAN_ACCOUNTS, false);
        commit(SET_LOAN_PRODUCTS_ALL, null);
        commit(SET_ERROR_CODE, e.response.status);
        commit(
          SET_LOAN_ACCOUNT_DETAILS_ERROR,
          "You are trying to access an account that you dont have access to. Kindly contact admin"
        );
      });
  },
  [GET_ALL_LOAN_PRODUCTS]({ commit }) {
    commit(LOADING_ALL_LOAN_PRODUCTS, true);
    LoanApiService.get(
      `LoanProduct/getloanproducts?pageindex=0&pagesize=1000&activestatus=true&includeDeleted=false&category=`
    )
      .then((res) => {
        commit(LOADING_ALL_LOAN_PRODUCTS, false);
        commit(SET_LOAN_PRODUCTS_ALL, res.data);
      })
      .catch(() => {
        commit(LOADING_ALL_LOAN_PRODUCTS, false);
        commit(SET_LOAN_PRODUCTS_ALL, null);
      });
  },
  [SAVE_LOAN_PRODUCTS]({ commit }, data) {
    commit(SAVING_LOAN_PRODUCTS, true);
    LoanApiService.post("LoanProduct/addloanproduct", data)
      .then(() => {
        commit(SAVING_LOAN_PRODUCTS, false);
        // commit(SET_LOAN_PRODUCTS, res.data);
      })
      .catch(() => {
        commit(SAVING_LOAN_PRODUCTS, false);
      });
  },
  [GET_LOAN_ACCOUNT_DETAILS]({ commit }, p) {
    commit(LOADING_LOAN_ACCOUNT_DETAILS, true);
    const payload = p ? p.loanAccountKey : p;
    LoanApiService.get(
      `LoanAccount/getloanaccountdetailsbyloanaccountid/${payload}`
    )
      .then((res) => {
        commit(LOADING_LOAN_ACCOUNT_DETAILS, false);
        commit(SET_ERROR_CODE, "");
        commit(SET_LOAN_ACCOUNT_DETAILS_ERROR, "");
        commit(SET_LOAN_ACCOUNT_DETAILS, res.data);
      })
      .catch((e) => {
        commit(LOADING_LOAN_ACCOUNT_DETAILS, false);
        commit(SET_ERROR_CODE, e.response.status);
        commit(SET_LOAN_ACCOUNT_DETAILS, null);
        commit(SET_LOAN_ACCOUNT_DETAILS_ERROR, e.response.data.message);
      });
  },
};

const getters = {
  loadProducts(state) {
    return state.products;
  },
};
export default {
  state,
  actions,
  mutations,
  getters,
  namespace: true,
};
