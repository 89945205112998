import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import access from "./access.module";
import activity from "./activity.module";
import auth from "./auth.module";
import banking from "./banking.module";
import breadcrumbs from "./breadcrumbs.module";
import bulkreassignment from "./bulkreassign.module.js";
import bulkrepayment from "./bulkrepayment.module";
import comment from "./comment.module";
import config from "./config.module";
import coreBankingModule from "./coreBanking.module";
import deposit from "./deposit.module";
import depositTransaction from "./depositTransaction.module.js";
import disbursement from "./disbursement.module";
import extensionapp from "./extensionapp.module.js";
import htmlClass from "./htmlclass.module";
import installments from "./installments.module.js";
import loan from "./loan.module";
import loanTransaction from "./loanTransaction.module.js";
import notification from "./notification.module";
import profile from "./profile.module";
import template from "./template.module";
import transactions from "./transactions.module";
import views from "./views.module";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    profile,
    loan,
    views,
    access,
    deposit,
    comment,
    transactions,
    template,
    notification,
    banking,
    disbursement,
    bulkrepayment,
    activity,
    loanTransaction,
    depositTransaction,
    installments,
    extensionapp,
    bulkreassignment,
    coreBankingModule,
  },
  plugins: [vuexLocal.plugin],
});
