// Global 3rd party plugins
import "popper.js";
import "bootstrap";
import "tooltip.js";
// Vue 3rd party plugins
// import i18n from "@/core/plugins/vue-i18n";
// import vuetify from "@/core/plugins/vuetify";
// import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
// import "@/core/plugins/perfect-scrollbar";
// import "@/core/plugins/highlight-js";
// import "@/core/plugins/inline-svg";
// import "@/core/plugins/apexcharts";
// import "@/core/plugins/treeselect";
// import "@/core/plugins/metronic";
// import "@mdi/font/css/materialdesignicons.css";
// import "@/core/plugins/formvalidation";
import "./components/_globals";
import "./assets/js/layout/base/scripts.min.js";
import "./filters/index";

import ApiService from "@/core/services/api.service";
import App from "./App.vue";
import CoreBankingService from "@/core/services/coreBanking.service";
import LoanApiService from "@/core/services/api.loan.service";
import PerfectScrollbar from "perfect-scrollbar";
import Vue from "vue";
// import VueQRCodeComponent from "vue-qrcode-component";
import Vuelidate from "vuelidate";
import router from "./router";
import store from "@/core/services/store";

// import MockService from "@/core/mock/mock.service";

Vue.config.productionTip = true;
// Vue.component("qr-code", VueQRCodeComponent);

window.PerfectScrollbar = PerfectScrollbar;
// import ClipboardJS from "clipboard";
// window.ClipboardJS = ClipboardJS;

// API service init
ApiService.init();
LoanApiService.init();
CoreBankingService.init();
Vue.config.ignoredElements = [/^ion-/];
Vue.use(Vuelidate);
Vue.use(require("vue-moment"));
Vue.config.ignoredElements = [/^ion-/];
// Remove this to disable mock API
// MockService.init();
Vue.use(Vuelidate);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
