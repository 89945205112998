<template>
  <div class="account__header__dropdown dropdown">
    <button
      class="btn dropdown__toggle topnav__secondary__dropdown"
      type="button"
      id="dropdownMenuButton"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <img src="assets/img/country.svg" alt="" srcset="" />
      <span class="dropdown__icon">
        <img src="assets/img/caret-down-black.svg" alt="" srcset="" />
      </span>
    </button>
    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
      <a class="dropdown-item" href="#">Nigeria</a>
      <a class="dropdown-item" href="#">Ghana</a>
      <a class="dropdown-item" href="#">USA</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Country",
};
</script>
