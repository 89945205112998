const ID_CP_TOKEN_KEY = "id_change_password_token";
const ID_CURRENT_PASSWORD = "id_current_password";

export const getCPToken = () => {
  return JSON.parse(window.localStorage.getItem(ID_CP_TOKEN_KEY));
};

export const saveCPToken = (token) => {
  window.localStorage.setItem(ID_CP_TOKEN_KEY, token);
};

export const destroyCPToken = () => {
  window.localStorage.removeItem(ID_CP_TOKEN_KEY);
};

export const getCurrentPassword = () => {
  return window.localStorage.getItem(ID_CURRENT_PASSWORD);
};

export const saveCurrentPassword = (password) => {
  window.localStorage.setItem(ID_CURRENT_PASSWORD, password);
};

export const destroyCurrentPassword = () => {
  window.localStorage.removeItem(ID_CURRENT_PASSWORD);
};

export default {
  getCPToken,
  saveCPToken,
  destroyCPToken,
  getCurrentPassword,
  saveCurrentPassword,
  destroyCurrentPassword,
};
