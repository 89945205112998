<template>
  <div id="accordion">
    <div class="accordion__element">
      <AccordionHeader
        v-bind:toggleId="'collapseOne'"
        v-bind:title="'Currency in use'"
      />

      <b-collapse
        id="collapseOne"
        class="collapse show"
        aria-labelledby="headingOne"
      >
        <div class="accordion__body">
          <table
            class="customtable customtable--nobottom customtable--bordertop w-100"
          >
            <thead>
              <tr>
                <th
                  scope="col"
                  class="table__header__text table__text"
                  style="width: 30%"
                >
                  Code
                </th>
                <th
                  scope="col"
                  class="table__header__text table__text"
                  style="width: 50%"
                >
                  Name
                </th>
                <th
                  scope="col"
                  class="table__header__text table__text"
                  style="width: 20%"
                >
                  Symbol
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span class="table__body__text table__text text--upper"
                    >ngn</span
                  >
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <span class="text--capital d-inline-block table__text mr-2"
                      >nigerian naira</span
                    >
                    <span class="tag tag__success text--capital">base</span>
                  </div>
                </td>
                <td>
                  <span class="table__body__text table__text text--capital"
                    >₦</span
                  >
                </td>
              </tr>
              <tr>
                <td>
                  <span class="table__body__text table__text text--upper"
                    >xof</span
                  >
                </td>
                <td>
                  <span class="table__body__text table__text text--capital"
                    >CFA Franc BCEAO</span
                  >
                </td>
                <td>
                  <span class="table__body__text table__text text--upper"
                    >cfa</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: String,
  },
};
</script>
