import JwtService from "@/core/services/jwt.service";
import Vue from "vue";
import VueAxios from "vue-axios";
import axios from "axios";

/**
 * Service to call HTTP request via Axios
 */

const key = "Sage-31d23e90-6765-4641-90af-36f15175c2fa";

const CoreBankingService = {
  init() {
    // Add a request interceptor
    axios.interceptors.request.use(
      (config) => {
        const token = JwtService.getToken();
        if (token) {
          config.headers["Key"] = `${key}`;
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );

    Vue.use(VueAxios, axios);
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common["Key"] = `${key}`;
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch((error) => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  get(resource, slug = "") {
    this.setHeader();
    if (slug == null || slug == "") {
      return Vue.axios.get(`${resource}`).catch((error) => {
        throw new Error(`[KT] ApiService ${error}`);
      });
    } else {
      return Vue.axios.get(`${resource}/${slug}`).catch((error) => {
        throw new Error(`[KT] ApiService ${error}`);
      });
    }
  },

  post(resource, params) {
    this.setHeader();
    return Vue.axios.post(`${resource}`, params);
  },

  update(resource, slug, params) {
    this.setHeader();
    if (slug == null || slug == undefined || slug == "") {
      return Vue.axios.put(`${resource}`, params);
    }
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  put(resource, params) {
    this.setHeader();
    return Vue.axios.put(`${resource}`, params);
  },

  delete(resource) {
    this.setHeader();
    return Vue.axios.delete(`${resource}`).catch((error) => {
      throw error;
    });
  },
};

export default CoreBankingService;
