<template>
  <div :class="{ page__head: underline }">
    <div class="page__head__container">
      <div class="page__head__icon" v-if="icon">
        <img src="../assets/img/page-icon.svg" alt="" srcset="" />
      </div>
      <div class="page__head__text">
        <h1 class="page__head__title">
          {{ title }}
        </h1>
        <p class="page__head__subtitle">
          {{ subTitle }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContentHeader",
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: String,
    icon: {
      type: Boolean,
      required: false,
      default: true,
    },
    underline: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
};
</script>
