import ApiService from "@/core/services/api.service";

// mutation types
export const GET_ALL_COMMENTS = "GET_ALL_COMMENTS";
export const setComment = "setComment";

export const CREATED_COMMENT = "CREATED_COMMENT";
export const setCreateComment = "setCreateComment";

export const DELETED_COMMENT = "DELETED_COMMENT";

export const UPDATED_COMMENT = "UPDATED_COMMENT";

export const commentErrors = "commentErrors";

const baseURL = process.env.VUE_APP_API_BASE_DEPOSIT_URL;

const comments_uri = `${baseURL}DepositAccount/GetComment`;
const create_comment_uri = `${baseURL}DepositAccount/createComment`;
const update_comment_uri = `${baseURL}DepositAccount/UpdateComment`;

const state = {
  allComments: [],
  errors: [],
};

const getters = {
  getComments: (state) => state.allComments,
};

const mutations = {
  setComment: (state, payload) => (state.allComments = payload),
  commentErrors: (state, payload) => (state.errors = payload),
};

const actions = {
  GET_ALL_COMMENTS({ commit }) {
    ApiService.get(comments_uri)
      .then((res) => {
        commit(setComment, res.data);
      })
      .catch((error) => {
        commit(commentErrors, error.message);
      });
  },

  CREATED_COMMENT({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(create_comment_uri, payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          commit(commentErrors, error.message);
          reject(error);
        });
    });
  },

  UPDATED_COMMENT({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put(update_comment_uri, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          commit(commentErrors, error.message);
          reject(error);
        });
    });
  },

  DELETED_COMMENT({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`DepositAccount/DeleteComment/${payload.commentKey}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          commit(commentErrors, error.message);
          reject(error);
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
