<template>
  <div class="accordion__header">
    <div class="d-flex align-items-center justify-content-between">
      <h5 class="mb-0 accordion__header__title">
        {{ title }}
      </h5>
      <b-button
        v-b-toggle="toggleId"
        class="btn accordion__header__button d-flex align-items-center justify-content-between"
      >
        <span class="mr-2"></span>
        <ion-icon name="chevron-down-outline"></ion-icon>
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    toggleId: String,
  },
};
</script>
