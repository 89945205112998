import { LOGOUT, REFRESH_TOKEN } from "./store/types";

import CPService from "@/core/services/change.password.service";
import JwtService from "@/core/services/jwt.service";
import Store from "@/core/services/store";
import Vue from "vue";
import VueAxios from "vue-axios";
import axios from "axios";
import router from "@/router";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    // Add a request interceptor
    axios.interceptors.request.use(
      (config) => {
        const token = JwtService.getToken();
        if (token) {
          config.headers["Authorization"] = `Bearer ${JwtService.getToken()}`;
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );

    //Add a response interceptor
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      function (error) {
        error.config.baseURL = process.env.VUE_APP_API_BASE_ACCESS_URL;
        if (error.response.status !== 401) {
          return Promise.reject(error);
        }

        // Logout user if token refresh didn't work or user is disabled
        if (error.config.url.includes("ProleanUser/refreshtoken")) {
          Store.dispatch(LOGOUT);
          router.push("/auth/login");
          return Promise.reject(error);
        }

        // refresh token
        // Try request again with new token
        Store.dispatch(REFRESH_TOKEN);
      }
    );

    Vue.use(VueAxios, axios);
  },

  getUrl() {
    return process.env.VUE_APP_API_BASE_ACCESS_URL;
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader(fromChangePassword = false) {
    if (fromChangePassword) {
      const cp_token = CPService.getCPToken();
      // this is used when the user is changing his own password from the user module
      const my_token = cp_token
        ? cp_token.access_Token.toString()
        : JwtService.getToken();
      Vue.axios.defaults.headers.common["Authorization"] = `Bearer ${my_token}`;
    } else {
      Vue.axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${JwtService.getToken()}`;
    }
    Vue.axios.defaults.headers.common["domain"] = window.location.host;
    // Vue.axios.defaults.headers.common[
    //   "domain"
    // ] = `simpletech.proleanfinancials.biz`;
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_BASE_ACCESS_URL;
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch((error) => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    // this.setHeader();
    const arr = ["generate-otp"];
    if (arr.includes(resource.split("/")[1])) {
      this.setHeader(true);
    } else {
      this.setHeader();
    }
    if (slug == null || slug == "") {
      return Vue.axios.get(`${resource}`).catch((error) => {
        throw new Error(`[KT] ApiService ${error}`);
      });
    } else {
      return Vue.axios.get(`${resource}/${slug}`).catch((error) => {
        throw new Error(`[KT] ApiService ${error}`);
      });
    }
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    const arr = ["changepassword", "verifyotp"];
    if (arr.includes(resource.split("/")[1])) {
      this.setHeader(true);
    } else {
      this.setHeader();
    }
    return Vue.axios.post(`${resource}`, params);
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  postFormData(resource, params) {
    this.setHeader();
    return Vue.axios.post(`${resource}`, params, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    this.setHeader();
    if (slug == null || slug == undefined || slug == "") {
      return Vue.axios.put(`${resource}`, params);
    }
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    this.setHeader();
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    this.setHeader();
    return Vue.axios.delete(`${resource}`).catch((error) => {
      throw error;
    });
  },

  /**
   * Send the PATCH HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  patch(resource, params) {
    this.setHeader();
    return Vue.axios.patch(`${resource}`, params);
  },
};

export default ApiService;
