import ApiService from "../api.service";
import GeneralApiService from "@/core/services/general.service";

export const GET_ALL_BULK_REASSIGNMENT = "GET_ALL_BULK_REASSIGNMENT";
export const setBulkReassignment = "setBulkReassignment";

export const GET_LOADING_REASSIGN_DATA = "GET_LOADING_REASSIGN_DATA";
export const setLoadingReassign = "setLoadingReassign";

export const bulkReassignErrors = "bulkReassignErrors";

const state = {
  allBulkReassign: [],
  errors: [],
  loading: true,
};

const getters = {
  allBulkReassign: (state) => state.allBulkReassign,
  getLoadingReassign: (state) => state.loading,
};

const mutations = {
  setBulkReassignment: (state, payload) => (state.allBulkReassign = payload),
  setLoadingReassign: (state, payload) => (state.loading = payload),
  bulkReassignErrors: (state, payload) => (state.errors = payload),
};

const actions = {
  GET_ALL_BULK_REASSIGNMENT({ commit }, payload) {
    commit(setLoadingReassign, true);
    commit(setBulkReassignment, { data: [] });
    if (payload.isFromFilter) {
      return new Promise((resolve, reject) => {
        GeneralApiService.post(`GeneralFilter/ApplyFilter`, payload)
          .then((response) => {
            const data = response.data;
            commit(setBulkReassignment, data);
            commit(setLoadingReassign, false);
            resolve(true);
          })
          .catch((error) => {
            commit(bulkReassignErrors, error.response.data.message);
            commit(setLoadingReassign, false);
            reject(error);
          });
      });
    } else {
      ApiService.post(`DepositAccount/get-all-accounts`, payload)
        .then((response) => {
          const data = response.data;
          commit(setBulkReassignment, data);
          commit(setLoadingReassign, false);
        })
        .catch((error) => {
          commit(bulkReassignErrors, error.response.data.message);
          commit(setLoadingReassign, false);
        });
    }
  },
};

export default { state, mutations, actions, getters };
