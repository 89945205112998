import GeneralApiService from "@/core/services/general.service";

// mutation types
export const GET_ALL_BANKS = "GET_ALL_BANKS";
export const setBanks = "setBanks";

export const GET_ACTIVE_BANKS = "GET_ACTIVE_BANKS";
export const setActiveBanks = "setActiveBanks";

export const UPDATE_BANK = "UPDATE_BANK";
export const BANK_UPDATED = "BANK_UPDATED";

export const DELETE_BANK = "DELETE_BANK";
export const DEACTIVATE_BANK = "DEACTIVATE_BANK";

export const bankErrors = "bankErrors";

const state = {
  allBanks: [],
  allActiveBanks: [],
  errors: [],
  bankUpdatedStatus: "",
};

const getters = {
  getBanks: (state) => state.allComments,
  getActiveBanks: (state) => state.allActiveBanks,
};

const mutations = {
  setBanks: (state, payload) => (state.allBanks = payload),
  setActiveBanks: (state, payload) => (state.allActiveBanks = payload),
  BANK_UPDATED: (state, params) => (state.bankUpdatedStatus = params),
  bankErrors: (state, payload) => (state.errors = payload),
};

const actions = {
  GET_ALL_BANKS({ commit }, { pageindex, pagesize }) {
    GeneralApiService.get(`Bank/banks/${pageindex}/${pagesize}`)
      .then((res) => {
        commit(setBanks, res.data.data);
      })
      .catch((error) => {
        commit(bankErrors, error.message);
      });
  },

  GET_ACTIVE_BANKS({ commit }) {
    GeneralApiService.get(`Bank/Activebanks`)
      .then((res) => {
        commit(setActiveBanks, res.data.data);
      })
      .catch((error) => {
        commit(bankErrors, error.message);
      });
  },

  UPDATE_BANK({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GeneralApiService.put(`Bank/${payload.bankKey}`, payload)
        .then((data) => {
          commit(BANK_UPDATED, data.message);
          resolve(true);
        })
        .catch((error) => {
          commit(bankErrors, error.message);
          reject(error);
        });
    });
  },

  DELETE_BANK({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GeneralApiService.delete(`Bank/${payload.accountKey}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          commit(bankErrors, error.message);
          reject(error);
        });
    });
  },

  DEACTIVATE_BANK({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GeneralApiService.put(
        `Bank/bankStatus/${payload.bankKey}/${payload.status}`
      )
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          commit(bankErrors, error.message);
          reject(error);
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
