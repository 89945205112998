import ApiService from "@/core/services/api.service";
import GeneralApiService from "@/core/services/general.service";

// /api/dashboardIndicators/CreateUpdateUserActivityTypeSettingsOnDashBoard
export const GET_ALL_ACTIVITIES = "GET_ALL_ACTIVITIES";
export const GET_ALL_CONFIGURED_ACTIVITIES = "GET_ALL_CONFIGURED_ACTIVITIES";
export const GET_LOADING_DATA = "GET_LOADING_DATA";
export const setLoading = "setLoading";
export const SetUserActivityType = "SetUserActivityType";
export const SetAllActivityTypes = "SetAllActivityTypes";
export const SetActivitiesExportData = "SetActivitiesExportData";
export const activityErrors = "activityErrors";

export const GET_ALL_SYSTEM_ACTIVITIES = "GET_ALL_SYSTEM_ACTIVITIES";
export const GET_ACTIVITIES_EXPORT_DATA = "GET_ACTIVITIES_EXPORT_DATA";
export const GET_ALL_SYSTEM_ACTIVITIES_LOADING =
  "GET_ALL_SYSTEM_ACTIVITIES_LOADING";
export const SET_SYSTEM_ACTIVITIES = "SET_SYSTEM_ACTIVITIES";
export const SET_ERROR_CODE = "SET_ERROR_CODE";
export const SET_ERROR = "SET_ERROR";

const state = {
  allActivity: [],
  allConfiguredActivity: [],
  errors: [],
  loading: true,
  allActivities: [],
  allActivitiesExport: [],
  errorCode: "",
  error: "",
  loadingActivities: false,
};

const getters = {
  getActivity: (state) => state.allActivity,
  getConfiguredActivity: (state) => state.allConfiguredActivity,
  // getLoading: (state) => state.loading,
};

const mutations = {
  SetUserActivityType: (state, payload) =>
    (state.allConfiguredActivity = payload),
  SetActivitiesExportData: (state, payload) =>
    (state.allActivitiesExport = payload),
  SetAllActivityTypes: (state, payload) => (state.allActivity = payload),
  setLoading: (state, payload) => (state.loading = payload),
  activityErrors: (state, payload) => (state.errors = payload),

  [SET_SYSTEM_ACTIVITIES](state, payload) {
    state.allActivities = payload;
  },
  [GET_ALL_SYSTEM_ACTIVITIES_LOADING](state, payload) {
    state.loadingActivities = payload;
  },
  [SET_ERROR_CODE](state, payload) {
    state.errorCode = payload;
  },
  [SET_ERROR](state, payload) {
    state.error = payload;
  },
};

const actions = {
  GET_ALL_ACTIVITIES({ commit }) {
    ApiService.get(`DepositAccount/GetActivityType`)
      .then((res) => {
        commit(SetAllActivityTypes, res.data);
      })
      .catch((error) => {
        commit(activityErrors, error.res.data.message);
      });
  },
  GET_ALL_CONFIGURED_ACTIVITIES({ commit }) {
    commit(SetUserActivityType, []);
    commit(setLoading, true);
    ApiService.get(`dashboardIndicators/getuseractivityconfigurationsettings`)
      .then((res) => {
        commit(setLoading, false);
        commit(SetUserActivityType, res.data);
      })
      .catch((error) => {
        commit(setLoading, false);
        commit(activityErrors, error);
      });
  },

  GET_ALL_SYSTEM_ACTIVITIES({ commit }, payload) {
    commit(GET_ALL_SYSTEM_ACTIVITIES_LOADING, true);
    if (payload.isFromFilter) {
      return new Promise((resolve, reject) => {
        GeneralApiService.post(`GeneralFilter/ApplyFilter`, payload)
          .then((response) => {
            commit(SET_SYSTEM_ACTIVITIES, response);
            commit(SET_ERROR_CODE, "");
            commit(GET_ALL_SYSTEM_ACTIVITIES_LOADING, false);
            commit(SET_ERROR, "");
            resolve(true);
          })
          .catch((error) => {
            commit(GET_ALL_SYSTEM_ACTIVITIES_LOADING, false);
            commit(SET_ERROR_CODE, error.response.status);
            commit(SET_ERROR, error.response.data.message);
            reject(error);
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        ApiService.post(`DepositAccount/GetActivities`, payload)
          .then((response) => {
            commit(GET_ALL_SYSTEM_ACTIVITIES_LOADING, false);
            commit(SET_SYSTEM_ACTIVITIES, response.data);
            commit(SetActivitiesExportData, response.data.data);
            commit(SET_ERROR_CODE, "");
            commit(SET_ERROR, "");
            resolve(true);
          })
          .catch((error) => {
            commit(GET_ALL_SYSTEM_ACTIVITIES_LOADING, false);
            commit(SET_ERROR_CODE, error.response.status);
            commit(SET_ERROR, error.response.data.message);
            reject(error);
          });
      });
    }
  },
  GET_ACTIVITIES_EXPORT_DATA({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GeneralApiService.post(`GeneralFilter/ApplyFilter`, payload)
        .then((response) => {
          commit(SetActivitiesExportData, response.data);
          commit(SET_ERROR_CODE, "");
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
