<template>
  <th scope="col" style="width: 18%" class="table__header__text table__text">
    {{ title }}
  </th>
</template>

<script>
export default {
  name: "ZedvanceTableTh",
  props: {
    title: String,
  },
};
</script>
