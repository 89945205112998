// import axios from "axios"
import Router from "vue-router";
import Store from "@/core/services/store";
import Vue from "vue";
// import { REFRESH_TOKEN } from "./core/services/store/types";

const { isNavigationFailure, NavigationFailureType } = Router;

const originalPush = Router.prototype.push;

Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((failure) => {
    if (isNavigationFailure(failure, NavigationFailureType.duplicated)) {
      window.location.reload();
      return;
    }
  });
};

Vue.use(Router);

let customer_id = "";
let product_id = "";

const router = new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/NewLayout"),
      children: [
        {
          path: "/administration",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue"),
          meta: { requiresAuth: true },
        },
        {
          path: "/components",
          name: "components",
          component: () => import("@/view/pages/components.vue"),
          meta: { requiresAuth: true },
        },
        {
          path: "/currency",
          name: "currency",
          component: () => import("@/view/pages/generalSetup/currency.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "GeneralSetup"
            );
            if (permission[0].permissionsList.CanViewCurrency) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/bank-setup",
          name: "bank-setup",
          component: () =>
            import("@/view/pages/generalSetup/banking/index.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "GeneralSetup"
            );
            if (permission[0].permissionsList.CanViewBank) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/organization-details",
          name: "organization-details",
          component: () => import("@/view/pages/generalSetup/orgDetails.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "GeneralSetup"
            );
            if (permission[0].permissionsList.CanViewOrganizationDetails) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/administration-menu",
          name: "administration-menu",
          component: () =>
            import("@/view/pages/AdministrationMenu/AdministrationMenu.vue"),
          meta: { requiresAuth: true },
        },
        {
          path: "/internal-controls",
          name: "internal-controls",
          component: () =>
            import("@/view/pages/generalSetup/internal-controls.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "GeneralSetup"
            );
            if (permission[0].permissionsList.CanViewInternalControl) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/all-customers",
          name: "client-customers",
          component: () =>
            import("@/view/pages/clients/customers/customers.vue"),
          meta: { layout: "FULL", requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "Client"
            );
            if (permission[0].permissionsList.CanViewClient) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/all-customers/:customerStatus",
          name: "client-customers-status",
          component: () =>
            import("@/view/pages/clients/customers/customers.vue"),
          meta: { layout: "FULL", requiresAuth: true },
        },
        {
          path: "/all-groups",
          name: "groups",
          component: () => import("@/view/pages/clients/groups/groups.vue"),
          meta: { layout: "FULL", requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "Group"
            );
            if (permission[0].permissionsList.CanViewGroup) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/group/:id",
          name: "group_overview",
          component: () =>
            import("@/view/pages/clients/groups/group_overview.vue"),
          meta: { layout: "FULL", requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "Group"
            );
            if (permission[0].permissionsList.CanViewGroup) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/customer/:id",
          name: "customer_overview",
          component: () =>
            import("@/view/pages/clients/customers/customer_overview.vue"),
          meta: { layout: "FULL", requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "Client"
            );
            if (permission[0].permissionsList.CanViewClient) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/deposit-account/:id",
          name: "deposit_view",
          component: () =>
            import("@/view/pages/deposit/account/deposit_view.vue"),
          meta: { layout: "FULL", requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "DepositAccount"
            );
            if (permission[0].permissionsList.CanViewSavingsAccount) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/id-templates",
          name: "id-templates",
          component: () => import("@/view/pages/generalSetup/id-templates.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "GeneralSetup"
            );
            if (permission[0].permissionsList.CanViewIDTemplate) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/risk-levels",
          name: "risk-levels",
          component: () => import("@/view/pages/generalSetup/risk-levels.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "GeneralSetup"
            );
            if (permission[0].permissionsList.CanViewRiskLevel) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/organization-branding",
          name: "organization-branding",
          component: () =>
            import("@/view/pages/generalSetup/organization-branding.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "GeneralSetup"
            );
            if (permission[0].permissionsList.CanUploadLogo) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/underwriting-reason",
          name: "underwriting-reason",
          component: () => import("@/view/pages/underwriting/reason.vue"),
          meta: { requiresAuth: true },
        },
        {
          path: "/rates",
          name: "rates",
          component: () => import("@/view/pages/generalSetup/rates.vue"),
          meta: { requiresAuth: true },
        },
        {
          path: "/group-roles",
          name: "group-roles",
          component: () => import("@/view/pages/generalSetup/group-roles.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "GeneralSetup"
            );
            if (permission[0].permissionsList.CanViewGroupRole) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },

        {
          path: "/customer-types",
          name: "customer-types",
          component: () =>
            import("@/view/pages/generalSetup/customer-types.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "GeneralSetup"
            );
            if (permission[0].permissionsList.CanViewCustomerTypes) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },

        // {
        //   path: "form",
        //   name: "vue-bootstrap-form",
        //   component: () =>
        //     import("@/view/layout/extras/offcanvas/QuickPanel.vue")
        // },
        {
          path: "/branches",
          name: "branches",
          component: () => import("@/view/pages/organization/branches.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "Branch"
            );
            if (permission[0].permissionsList.CanViewBranch) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/centres",
          name: "centres",
          component: () => import("@/view/pages/organization/centres.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "Centre"
            );
            if (permission[0].permissionsList.CanViewCentre) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/field-generic/:type",
          name: "field-generic",
          component: () => import("@/view/pages/fields/generic.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "CustomField"
            );
            if (permission[0].permissionsList.CanViewCustomField) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/field-group",
          name: "field-group",
          component: () => import("@/view/pages/fields/groups.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "CustomField"
            );
            if (permission[0].permissionsList.CanViewCustomField) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/field-customers",
          name: "field-customers",
          component: () => import("@/view/pages/fields/customers.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "CustomField"
            );
            if (permission[0].permissionsList.CanViewCustomField) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/field-branches",
          name: "field-branches",
          component: () => import("@/view/pages/fields/branches.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "CustomField"
            );
            if (permission[0].permissionsList.CanViewCustomField) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/field-centres",
          name: "field-centres",
          component: () => import("@/view/pages/fields/centres.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "CustomField"
            );
            if (permission[0].permissionsList.CanViewCustomField) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/field-loan-accounts",
          name: "field-loan-accounts",
          component: () => import("@/view/pages/fields/loan-accounts.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "CustomField"
            );
            if (permission[0].permissionsList.CanViewCustomField) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/field-deposit-accounts",
          name: "field-deposit-accounts",
          component: () => import("@/view/pages/fields/deposit-accounts.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "CustomField"
            );
            if (permission[0].permissionsList.CanViewCustomField) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/field-transactions",
          name: "field-transactions",
          component: () => import("@/view/pages/fields/transactions.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "CustomField"
            );
            if (permission[0].permissionsList.CanViewCustomField) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/field-users",
          name: "field-users",
          component: () => import("@/view/pages/fields/users.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "CustomField"
            );
            if (permission[0].permissionsList.CanViewCustomField) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "calendars",
          name: "vuetify-calendars",
          component: () => import("@/view/pages/vuetify/Calendars.vue"),
          meta: { requiresAuth: true },
        },
        {
          path: "/views",
          name: "views",
          component: () => import("@/view/pages/views/index.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "Views"
            );
            if (permission[0].permissionsList.CanViewViews) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/viewreport/:id",
          name: "views_report",
          props: true,
          component: () => import("@/view/pages/views/report.vue"),
          meta: { layout: "FULL", requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "Group"
            );
            const permission2 = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "Client"
            );
            if (
              permission[0].permissionsList.CanViewGroup ||
              permission2[0].permissionsList.CanViewClient
            ) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/field-guarantors",
          name: "field-guarantors",
          component: () => import("@/view/pages/fields/guarantors.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "CustomField"
            );
            if (permission[0].permissionsList.CanViewCustomField) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          name: "loans",
          path: "/loans",
          component: () => import("@/view/pages/loans/index.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "Product"
            );
            if (permission[0].permissionsList.CanViewLoanProduct) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/deposits",
          name: "deposits",
          component: () => import("@/view/pages/deposit/deposit.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "Product"
            );
            if (permission[0].permissionsList.CanViewSavingsProduct) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/roles",
          name: "roles",
          component: () => import("@/view/pages/Access/Roles.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "Access"
            );
            if (permission[0].permissionsList.CanViewRole) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/users",
          name: "users",
          component: () => import("@/view/pages/Access/Users.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "Access"
            );
            if (permission[0].permissionsList.CanViewUser) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/preferences",
          name: "preference",
          component: () => import("@/view/pages/Access/Preference.vue"),
          meta: { requiresAuth: true },
        },
        {
          path: "/authentication",
          name: "authentication",
          component: () => import("@/view/pages/Access/Authentication.vue"),
          meta: { requiresAuth: true },
        },
        {
          path: "/underwriting",
          name: "underwriting",
          component: () => import("@/view/pages/underwriting/underwriting.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "Underwriting"
            );
            if (permission[0].permissionsList.CanViewQueues) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/teams_management",
          name: "teams_management",
          component: () =>
            import("@/view/pages/teams_management/TeamsView.vue"),
          meta: { layout: "FULL", requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "TeamManagement"
            );
            if (permission[0].permissionsList.CanViewTeam) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/sales_report_summary",
          name: "sales_report_summary",
          component: () =>
            import("@/view/pages/sales_report/SalesSummaryReport.vue"),
          meta: { requiresAuth: true },
          // beforeEnter: (to, from, next) => {
          //   const permission = Store.state.auth.user.scopes.filter(x => x.scopeName == "Underwriting");
          //   if (permission[0].permissionsList.CanViewQueues) {
          //     next()
          //   } else {
          //     next(from.fullPath)
          //     alert("You do not have permission to access the requested page.")
          //   }
          // },
        },
        {
          path: "/sales_report_details/:id",
          name: "sales_report_details",
          component: () =>
            import("@/view/pages/sales_report/SalesReportDetails.vue"),
          meta: { layout: "FULL", requiresAuth: true },
          props: true,
          // beforeEnter: (to, from, next) => {
          //   const permission = Store.state.auth.user.scopes.filter(x => x.scopeName == "Underwriting");
          //   if (permission[0].permissionsList.CanViewQueues) {
          //     next()
          //   } else {
          //     next(from.fullPath)
          //     alert("You do not have permission to access the requested page.")
          //   }
          // },
        },
        {
          path: "/underwriting-dashboard",
          name: "underwriting-dashboard",
          component: () => import("@/view/pages/underwriting/dashboard.vue"),
          meta: { layout: "FULL", requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "Underwriting"
            );
            if (permission[0].permissionsList.CanViewUnderwritingdashboard) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/underwriting-general",
          name: "underwriting-general",
          component: () => import("@/view/pages/underwriting/general.vue"),
          meta: { layout: "FULL", requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "Underwriting"
            );
            if (
              permission[0].permissionsList.CanViewGeneralUnderwrittingDashboard
            ) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/underwriting-accounts/:id/:taskKey",
          name: "underwriting_accounts",
          component: () => import("@/view/pages/underwriting/details.vue"),
          meta: { layout: "FULL", requiresAuth: true },
        },
        {
          path: "/disbursement",
          name: "disbursement",
          component: () =>
            import("@/view/pages/underwriting/disbursement/index.vue"),
          meta: { layout: "FULL", requiresAuth: true },
        },
        {
          path: "/deposit-accounts",
          name: "deposit-accounts",
          component: () => import("@/view/pages/deposit/account.vue"),
          meta: { layout: "FULL", requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "DepositAccount"
            );
            if (permission[0].permissionsList.CanViewSavingsAccount) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/bulk-repayment-manager",
          name: "bulk-repayment-manager",
          component: () =>
            import(
              "@/view/pages/bulk_repayment/manager/BulkRepaymentManager.vue"
            ),
          meta: { layout: "FULL", requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "TeamManagement"
            );
            if (permission[0].permissionsList.CanBulkReassignAccountOfficer) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/bulk-repayment-approval",
          name: "bulk-repayment-approval",
          component: () =>
            import(
              "@/view/pages/bulk_repayment/approval/BulkRepaymentApproval.vue"
            ),
          meta: { layout: "FULL", requiresAuth: true },
        },
        {
          path: "/bulk-reassign-account-officer",
          name: "bulk-reassign-account-officer",
          component: () =>
            import(
              "@/view/pages/AccountOfficerBulkAssignment/BulkAssignment.vue"
            ),
          meta: { layout: "FULL", requiresAuth: true },
        },
        {
          name: "loan_products",
          path: "/loan/products",
          component: () => import("@/view/pages/loans/index.vue"),
          meta: { requiresAuth: true },
        },
        {
          name: "create_loan_account",
          path: "/loan/account/create",
          query: {
            customer_id: customer_id,
            product_id: product_id,
          },
          component: () =>
            import("@/view/pages/loans/create_loan_account_page.vue"),
          meta: { layout: "FULL", requiresAuth: true },
        },
        {
          name: "edit_loan_account",
          path: "/loan/account/:loanAccountKey/edit",
          component: () =>
            import("@/view/pages/loans/edit_loan_account_page.vue"),
          meta: { layout: "FULL", requiresAuth: true },
        },
        {
          name: "loan_accounts_index",
          path: "/loan/accounts",
          component: () => import("@/view/pages/loans/accounts.vue"),
          meta: { layout: "FULL", requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "LoanAccounts"
            );
            if (permission[0].permissionsList.CanViewLoanAccount) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          name: "loan_accounts_filtered",
          path: "/loan/pendingorpartialaccounts",
          component: () =>
            import("@/view/pages/loans/pending_partial_accounts.vue"),
          meta: { layout: "FULL", requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "LoanAccounts"
            );
            if (permission[0].permissionsList.CanViewLoanAccount) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },

        {
          name: "loan_accounts_details",
          path: "/loan/accounts/:loanAccountKey",
          component: () =>
            import("@/view/pages/loans/account_details/index.vue"),
          meta: { layout: "FULL", requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "LoanAccounts"
            );
            if (permission[0].permissionsList.CanViewLoanAccount) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },

        {
          name: "loan_accounts_disbursement_details",
          path: "/loan/accounts_details/:loanAccountKey/:disbursementKey",
          component: () =>
            import(
              "@/view/pages/loans/account_details/indexForDisbursement.vue"
            ),
          meta: { layout: "FULL", requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "LoanAccounts"
            );
            if (permission[0].permissionsList.CanViewLoanAccount) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },

        {
          path: "/dashboard",
          name: "tasks",
          component: () => import("@/view/pages/deposit/task/index.vue"),
          meta: { layout: "FULL", requiresAuth: true },
        },
        {
          path: "/all-tasks",
          name: "all_tasks",
          component: () => import("@/view/pages/deposit/task/all_tasks.vue"),
          meta: { layout: "FULL", requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "Tasks"
            );
            if (permission[0].permissionsList.CanViewTask) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/all-tasks/:customerKey",
          name: "customer_tasks",
          component: () => import("@/view/pages/deposit/task/customerTask.vue"),
          meta: { layout: "FULL", requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "Tasks"
            );
            if (permission[0].permissionsList.CanViewTask) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/all-group-tasks/:groupKey",
          name: "group_tasks",
          component: () => import("@/view/pages/tasks/tasks.vue"),
          meta: { layout: "FULL", requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "Tasks"
            );
            if (permission[0].permissionsList.CanViewTask) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/states",
          name: "state",
          component: () => import("@/view/pages/generalSetup/states.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "GeneralSetup"
            );
            if (permission[0].permissionsList.CanViewState) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/sub-categories",
          name: "sub-category",
          component: () =>
            import("@/view/pages/generalSetup/sub-categories.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "EmployerSetup"
            );
            if (permission[0].permissionsList.CanViewEmployerSubCategory) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/employers-categories",
          name: "employers-category",
          component: () =>
            import("@/view/pages/generalSetup/employers-categories.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "EmployerSetup"
            );
            if (permission[0].permissionsList.CanViewEmployerCategory) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/employers",
          name: "employer",
          component: () => import("@/view/pages/generalSetup/employers.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "EmployerSetup"
            );
            if (permission[0].permissionsList.CanViewEmployer) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/notifications",
          name: "notifications",
          component: () => import("@/view/pages/notifications/index.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "Communications"
            );
            if (permission[0].permissionsList.CanViewNotification) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/predefined-notifications",
          name: "predefined-notifications",
          component: () =>
            import("@/view/pages/notifications/groupNotification.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "Communications"
            );
            if (permission[0].permissionsList.CanViewPredefinedNotification) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/task-templates",
          name: "task_templates",
          component: () => import("@/view/pages/sms_templates/tasks/index.vue"),
          meta: { requiresAuth: true },
        },
        {
          path: "/templates",
          name: "sms_template",
          component: () => import("@/view/pages/sms_templates/sms/index.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "Templates"
            );
            if (permission[0].permissionsList.CanViewTemplate) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/finance_coa",
          name: "finance_coa",
          component: () =>
            import("@/view/pages/SageIntegration/ViewFinances.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "Accounting"
            );
            if (permission[0].permissionsList.CanManageAccount) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/apps",
          name: "extension_apps",
          component: () => import("@/view/pages/apps/index.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "Apps"
            );
            if (permission[0].permissionsList.CanViewApp) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/extension/:app_key/EXTENSION_MENU",
          name: "iframeapp",
          component: () => import("@/view/pages/apps/Extension.vue"),
          meta: { layout: "FULL", requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "Apps"
            );
            if (permission[0].permissionsList.CanViewApp) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/view-bulk-manager/:key",
          name: "view_bulk_manager",
          component: () => import("@/view/pages/bulkManager/viewManager.vue"),
          meta: { layout: "FULL", requiresAuth: true },
        },
        {
          path: "/all-branches",
          name: "all_branches",
          component: () => import("@/view/pages/organization/allBranches.vue"),
          meta: { layout: "FULL", requiresAuth: true },
          beforeEnter: (to, from, next) => {
            if (to === from) {
              window.location.reload();
              next();
            }
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "Branch"
            );
            if (permission[0].permissionsList.CanViewBranch) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/all-centres",
          name: "all_centres",
          component: () => import("@/view/pages/organization/allCentres.vue"),
          meta: { layout: "FULL", requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "Centre"
            );
            if (permission[0].permissionsList.CanViewCentre) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/all-users",
          name: "User",
          component: () => import("@/view/pages/Access/AllUsers.vue"),
          meta: { layout: "FULL", requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "Access"
            );
            if (permission[0].permissionsList.CanViewUser) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/user/:id",
          name: "UserOverview",
          component: () => import("@/view/pages/Access/Users/userOverview.vue"),
          meta: { layout: "FULL", requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "Access"
            );
            if (permission[0].permissionsList.CanViewUser) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/all-deposit-transactions",
          name: "DepositTransaction",
          component: () =>
            import("@/view/pages/MenuActions/allDepositTransactions.vue"),
          meta: { layout: "FULL", requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "DepositAccount"
            );
            if (permission[0].permissionsList.CanViewSavingsAccount) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/all-loan-transactions",
          name: "LoanTransactions",
          component: () =>
            import("@/view/pages/MenuActions/allLoanTransactions.vue"),
          meta: { layout: "FULL", requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "LoanAccounts"
            );
            if (permission[0].permissionsList.CanViewLoanAccount) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/all-activities",
          name: "Activities",
          component: () => import("@/view/pages/MenuActions/allActivities.vue"),
          meta: { layout: "FULL", requiresAuth: true },
          beforeEnter: (to, from, next) => {
            const permission = Store.state.auth.user.scopes.filter(
              (x) => x.scopeName == "Activity"
            );
            if (permission[0].permissionsList.CanViewActivities) {
              next();
            } else {
              next(from.fullPath);
              alert("You do not have permission to access the requested page.");
            }
          },
        },
        {
          path: "/all-installment",
          name: "Installment",
          component: () =>
            import("@/view/pages/MenuActions/allInstallment.vue"),
          meta: { layout: "FULL", requiresAuth: true },
        },
      ],
    },

    // // New layout
    // {
    //   path: "/",
    //   redirect: "/dashboard",
    //   component: () => import("@/view/layout/NewLayout"),
    //   children: [
    //     {
    //       path: "/dashboard",
    //       name: "dashboard",
    //       component: () => import("@/view/pages/Dashboard.vue")
    //     },
    //
    //   ]
    // },
    {
      path: "/auth/login",
      name: "login",
      component: () => import("@/view/pages/auth/Login.vue"),
    },
    {
      path: "/auth/register",
      name: "register",
      component: () => import("@/view/pages/auth/Register.vue"),
    },
    {
      path: "/auth/forgot-password",
      name: "forgotPassword",
      component: () => import("@/view/pages/auth/forgotPassword.vue"),
    },
    {
      path: "/auth/password-reset",
      name: "passwordReset",
      component: () => import("@/view/pages/auth/passwordReset.vue"),
    },
    {
      path: "/auth/change-password",
      name: "changePassword",
      component: () => import("@/view/pages/auth/ChangePassword.vue"),
    },
    {
      path: "/auth/two-factor-auth",
      name: "TwoFactorAuth",
      component: () => import("@/view/pages/auth/TwoFactorAuth.vue"),
    },
    {
      path: "/auth/configure-two-factor-auth",
      name: "Configure2Fa",
      component: () => import("@/view/pages/auth/configure2fa/Configure.vue"),
    },
    {
      path: "/auth/download-2fa-app",
      name: "DownloadApp",
      component: () => import("@/view/pages/auth/configure2fa/DownloadApp.vue"),
    },
    {
      path: "/auth/scan-qr-code",
      name: "ScanQrCode",
      component: () => import("@/view/pages/auth/configure2fa/ScanQrCode.vue"),
    },
    {
      path: "/onboarding",
      name: "onboarding",
      component: () =>
        import("@/view/pages/auth/onboarding/onboardingIndex.vue"),
    },
    {
      path: "/onboarding-success",
      name: "onboardingSuccess",
      component: () =>
        import("@/view/pages/auth/onboarding/onboardSuccess.vue"),
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue"),
    },
  ],
  linkActiveClass: "submenu__link--active",
  linkExactActiveClass: "exact-active",
});
// let isAlreadyRefreshingAccessToken = false
// let subscribers = []

// function onAccessTokenFetched(access_token) {
//   subscribers = subscribers.filter(callback => callback(access_token))
// }

// function addSubscriber(callback) {
//   subscribers.push(callback)
// }

// axios.interceptors.response.use(function (response) {
//   return response
// }, function (error) {
//   const { config, response: { status } } = error
//   const originalRequest = config

//   if (status === 401) {
//     if (!isAlreadyRefreshingAccessToken) {
//       isAlreadyRefreshingAccessToken = true

//       // instead of this store call you would put your code to get new token
//       Store.dispatch(REFRESH_TOKEN).then((access_token) => {
//         isAlreadyRefreshingAccessToken = false
//         onAccessTokenFetched(access_token)
//       })
//     }

//     const retryOriginalRequest = new Promise((resolve) => {
//       addSubscriber(access_token => {
//         originalRequest.headers.Authorization = 'Bearer ' + access_token
//         resolve(axios(originalRequest))
//       })
//     })
//     return retryOriginalRequest
//   }
//   return Promise.reject(error)
// })

router.beforeEach((to, from, next) => {
  //Implements auth
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!Store.state.auth.user.access_Token) {
      next("/auth/login");
    } else {
      next();
    }
  } else {
    next();
  }

  if (to.fullPath == "/auth/login") {
    if (Store.state.auth.user.access_Token) {
      next("/dashboard");
    } else {
      next();
    }
  } else if (to.fullPath == "/auth/password-reset") {
    if (Store.state.auth.user.access_Token) {
      next("/dashboard");
    } else {
      next();
    }
  } else if (to.fullPath == "/auth/forgot-password") {
    if (Store.state.auth.user.access_Token) {
      next("/dashboard");
    } else {
      next();
    }
  } else if (to.fullPath == "/dashboard") {
    if (!Store.state.auth.user.access_Token) {
      next("/auth/login");
    } else {
      next();
    }
  }
});

export default router;
