/* eslint-disable */
import LoanApiService from "@/core/services/api.loan.service";
import GeneralApiService from "@/core/services/general.service";

export const GET_ALL_LOAN_TRANSACTIONS = "GET_ALL_LOAN_TRANSACTIONS";
export const GET_EXPORT_LOAN_TRANSACTIONS = "GET_EXPORT_LOAN_TRANSACTIONS";
export const GET_ALL_LOAN_TRANSACTIONS_LOADING = "GET_ALL_LOAN_TRANSACTIONS_LOADING";
export const SET_LOAN_TRANSACTIONS = "SET_LOAN_TRANSACTIONS";
export const SET_EXPORT_LOAN_TRANSACTIONS = "SET_EXPORT_LOAN_TRANSACTIONS";
export const SET_ERROR_CODE = "SET_ERROR_CODE";

const state = {
    allLoanTransactions: [],
    allLoanExports: {},
    error: '',
    loadingLoanTransaction: false,
}

const mutations = {
    [SET_LOAN_TRANSACTIONS](state, payload) {
        state.allLoanTransactions = payload;
    },

    [SET_EXPORT_LOAN_TRANSACTIONS](state, payload) {
        state.allLoanExports = payload;
    },

    [GET_ALL_LOAN_TRANSACTIONS_LOADING](state, payload) {
        state.loadingLoanTransaction = payload;
    }, 
}

const actions = {
    GET_ALL_LOAN_TRANSACTIONS({ commit }, payload) {
        commit(GET_ALL_LOAN_TRANSACTIONS_LOADING, true)
        if (payload.isFromFilter) {
            return new Promise((resolve, reject) => {
                GeneralApiService.post(`GeneralFilter/ApplyFilter`, payload)
                    .then(response => {
                        commit(GET_ALL_LOAN_TRANSACTIONS_LOADING, false)
                        commit(SET_LOAN_TRANSACTIONS, response.data)
                        resolve(true)
                    })
                    .catch(error => {
                        commit(GET_ALL_LOAN_TRANSACTIONS_LOADING, false)
                        commit(error, error.response.data.message)
                        reject(error)
                    })
            })
        } else {
            return new Promise((resolve, reject) => {
                LoanApiService.post(`LoanAccount/get-all-loanaccountdetails-transactions`, payload)
                .then(response => {
                    commit(GET_ALL_LOAN_TRANSACTIONS_LOADING, false)
                    commit(SET_EXPORT_LOAN_TRANSACTIONS, response.data)
                    commit(SET_LOAN_TRANSACTIONS, response.data)
                    resolve(true)
                })
                .catch(error => {
                    commit(GET_ALL_LOAN_TRANSACTIONS_LOADING, false)
                    commit(error, error.response.data.message)
                    reject(error)
                })
            })
        }
    },
    GET_EXPORT_LOAN_TRANSACTIONS({ commit }, payload) {
        commit(GET_ALL_LOAN_TRANSACTIONS_LOADING, true)
        return new Promise((resolve, reject) => {
            LoanApiService.post(`LoanAccount/get-all-loanaccountdetails-transactions`, payload)
            .then(response => {
                commit(GET_ALL_LOAN_TRANSACTIONS_LOADING, false)
                commit(SET_EXPORT_LOAN_TRANSACTIONS, response.data)
                resolve(true)
            })
            .catch(error => {
                commit(GET_ALL_LOAN_TRANSACTIONS_LOADING, false)
                commit(error, error.response.data.message)
                reject(error)
            })
        })
    }
}

export default {
    state,
    actions,
    mutations
};