import GeneralApiService from "@/core/services/general.service";
/* eslint-disable */
import LoanApiService from "@/core/services/api.loan.service";

export const GET_ALL_INSTALLMENTS = "GET_ALL_INSTALLMENTS";
export const GET_ALL_INSTALLMENTS_LOADING = "GET_ALL_INSTALLMENTS_LOADING";
export const SET_INSTALLMENTS = "SET_INSTALLMENTS";
export const SET_ERROR_CODE = "SET_ERROR_CODE";

export const GET_ALL_INSTALLMENT_EXPORTS = "GET_ALL_INSTALLMENT_EXPORTS";
export const setAllInstallmentsExports = "setAllInstallmentsExports";

const state = {
    allInstallments: [],
    error: '',
    installmentLoading: false,
    allInstallmentsExports: [],
}

const mutations = {
    [SET_INSTALLMENTS](state, payload) {
        state.allInstallments = payload;
    },

    [GET_ALL_INSTALLMENTS_LOADING](state, payload) {
        state.installmentLoading = payload;
    }, 
    setAllInstallmentsExports: (state, payload) => state.allInstallmentsExports = payload,
}

const actions = {
    GET_ALL_INSTALLMENTS({ commit }, payload) {
        commit(GET_ALL_INSTALLMENTS_LOADING, true)
        if(payload.isFromFilter) {
            return new Promise((resolve, reject) => {
                GeneralApiService.post(`GeneralFilter/ApplyFilter`, payload)
                    .then(response => {
                        commit(GET_ALL_INSTALLMENTS_LOADING, false)
                        commit(SET_INSTALLMENTS, response.data)
                        resolve(true)
                    })
                    .catch(error => {
                        commit(GET_ALL_INSTALLMENTS_LOADING, false)
                        commit(error, error.response.data.message)
                        reject(error)
                    })
            })
        } else {
            return new Promise((resolve, reject) => {
                LoanApiService.post(`LoanAccount/get-loanaccounts-schedules`, payload)
                .then(response => {
                    commit(GET_ALL_INSTALLMENTS_LOADING, false)
                    commit(SET_INSTALLMENTS, response.data)
                    commit(setAllInstallmentsExports, response.data)
                    resolve(true)
                })
                .catch(error => {
                    commit(GET_ALL_INSTALLMENTS_LOADING, false)
                    commit(error, error.response.data.message)
                    reject(error)
                })
            })
        }
    },

    GET_ALL_INSTALLMENT_EXPORTS({ commit }, payload) {
        return new Promise((resolve, reject) => {
            GeneralApiService.post(`GeneralFilter/ApplyFilter`, payload)
                .then(response => {
                    commit(setAllInstallmentsExports, response.data)
                    resolve(true)
                })
                .catch(error => {
                    commit(error, error.response.data.message)
                    reject(error)
                })
        })
    },
}

export default {
    state,
    actions,
    mutations
};