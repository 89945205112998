/* eslint-disable */
import ApiService from "@/core/services/api.service";
import GeneralApiService from "@/core/services/general.service";

export const GET_ALL_DEPOSIT_TRANSACTIONS = "GET_ALL_DEPOSIT_TRANSACTIONS";

export const GET_ALL_DEPOSIT_TRANSACTIONS_EXPORTS = "GET_ALL_DEPOSIT_TRANSACTIONS_EXPORTS";
export const setAllDepositTransactionsExports = "setAllDepositTransactionsExports";

export const GET_ALL_DEPOSIT_TRANSACTIONS_LOADING = "GET_ALL_DEPOSIT_TRANSACTIONS_LOADING";
export const SET_DEPOSIT_TRANSACTIONS = "SET_DEPOSIT_TRANSACTIONS";
export const SET_ERROR_CODE = "SET_ERROR_CODE";
export const SET_ERROR = "SET_ERROR";

const state = {
    allDepositTransactions: [],
    errorCode: '',
    error: '',
    loadingDepositTransaction: false,
    allDepositTransactionsExports: [],
}

const mutations = {
    [SET_DEPOSIT_TRANSACTIONS](state, payload) {
        state.allDepositTransactions = payload;
    },

    [GET_ALL_DEPOSIT_TRANSACTIONS_LOADING](state, payload) {
        state.loadingDepositTransaction = payload;
    }, 

    [SET_ERROR_CODE](state, payload) {
        state.errorCode = payload;
    },

    [SET_ERROR](state, payload) {
        state.error = payload;
    },
    setAllDepositTransactionsExports: (state, payload) => state.allDepositTransactionsExports = payload,
}

const actions = {
    GET_ALL_DEPOSIT_TRANSACTIONS({ commit }, payload) {
        commit(GET_ALL_DEPOSIT_TRANSACTIONS_LOADING, true)
        if(payload.isFromFilter) {
            return new Promise((resolve, reject) => {
                GeneralApiService.post(`GeneralFilter/ApplyFilter`, payload)
                    .then(response => {
                        commit(GET_ALL_DEPOSIT_TRANSACTIONS_LOADING, false)
                        commit(SET_DEPOSIT_TRANSACTIONS, response.data)
                        resolve(true)
                    })
                    .catch(error => {
                        commit(GET_ALL_DEPOSIT_TRANSACTIONS_LOADING, false)
                        commit(SET_ERROR_CODE, error.response.status)
                        commit(SET_ERROR, error.response.data.message)
                        reject(error)
                    })
            })
        } else {
            return new Promise((resolve, reject) => {
                ApiService.post(`Transactions/get-filtered-transactions`, payload)
                    .then(response => {
                        commit(GET_ALL_DEPOSIT_TRANSACTIONS_LOADING, false)
                        commit(SET_DEPOSIT_TRANSACTIONS, response.data)
                        commit(setAllDepositTransactionsExports, response.data)
                        resolve(true)
                    })
                    .catch(error => {
                        commit(GET_ALL_DEPOSIT_TRANSACTIONS_LOADING, false)
                        commit(SET_ERROR_CODE, error.response.status)
                        commit(SET_ERROR, error.response.data.message)
                        reject(error)
                    })
            })
        }
    },
    GET_ALL_DEPOSIT_TRANSACTIONS_EXPORTS({ commit }, payload) {
        return new Promise((resolve, reject) => {
            GeneralApiService.post(`GeneralFilter/ApplyFilter`, payload)
                .then(response => {
                    commit(setAllDepositTransactionsExports, response.data)
                    resolve(true)
                })
                .catch(error => {
                    commit(SET_ERROR_CODE, error.response.status)
                    commit(SET_ERROR, error.response.data.message)
                    reject(error)
                })
        })
    },
}

export default {
    state,
    actions,
    mutations
};