// GET ALL ACCOUNTS
export const GET_ALL_ACCOUNTS_SUCCESS = "GET_ALL_ACCOUNTS_SUCCESS";
export const GET_ALL_ACCOUNTS_LOADING = "GET_ALL_ACCOUNTS_LOADING";
export const GET_ALL_ACCOUNTS = "GET_ALL_ACCOUNTS";
export const SET_ACCOUNT_ERROR = "SET_ACCOUNT_ERROR";

//POST ACCOUNT
export const POST_ACCOUNT_SUCCESS = "POST_ACCOUNT_SUCCESS";
export const POST_ACCOUNT_LOADING = "POST_ACCOUNT_LOADING";
export const POST_ACCOUNT = "POST_ACCOUNT";
export const SET_POST_ACCOUNT_ERROR_CODE = "SET__POST_ACCOUNT_ERROR_CODE";

//UPDATE ACCOUNT
export const UPDATE_ACCOUNT_SUCCESS = "UPDATE_ACCOUNT_SUCCESS";
export const UPDATE_ACCOUNT_LOADING = "UPDATE_ACCOUNT_LOADING";
export const UPDATE_ACCOUNT_ERROR = "UPDATE_ACCOUNT_ERROR";
export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";

//DELETE ACCOUNT
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_LOADING = "DELETE_ACCOUNT_LOADING";
export const DELETE_ACCOUNT_ERROR = "DELETE_ACCOUNT_ERROR";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";

//GET ALL DATASET MAPPING
export const GET_ALL_DATASET_MAPPING_SUCCESS =
  "GET_ALL_DATASET_MAPPING_SUCCESS";
export const GET_ALL_DATASET_MAPPING_LOADING =
  "GET_ALL_DATASET_MAPPING_LOADING";
export const GET_ALL_DATASET_MAPPING_ERROR = "GET_ALL_DATASET_MAPPING_ERROR";
export const GET_ALL_DATASET_MAPPING = "GET_ALL_DATASET_MAPPING";

//POST DATASET MAPPING
export const POST_DATASET_MAPPING_SUCCESS = "POST_DATASET_MAPPING_SUCCESS";
export const POST_DATASET_MAPPING_LOADING = "POST_DATASET_MAPPING_LOADING";
export const POST_DATASET_MAPPING_ERROR = "POST_DATASET_MAPPING_ERROR";
export const POST_DATASET_MAPPING = "POST_DATASET_MAPPING";

//GET SAGE TRANSACTION LOGS
export const GET_SAGE_TRANSACTIONS_SUCCESS = "GET_SAGE_TRANSACTIONS_SUCCESS";
export const GET_SAGE_TRANSACTIONS_LOADING = "GET_SAGE_TRANSACTIONS_LOADING";
export const GET_SAGE_TRANSACTIONS_ERROR = "GET_SAGE_TRANSACTIONS_ERROR";
export const GET_SAGE_TRANSACTIONS = "GET_SAGE_TRANSACTIONS";

export const baseUrl = process.env.VUE_APP_API_BASE_ACCOUNTING_URL;
