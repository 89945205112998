<template>
  <b-dropdown text="Primary" class="m-2 dropdown--button-grey">
    <b-dropdown-item href="#">Action</b-dropdown-item>
    <b-dropdown-item href="#">Another action</b-dropdown-item>
    <b-dropdown-item href="#">Something else here</b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  name: "ZedvanceDropdown",
  props: {
    id: Number,
    active: Number,
    open: Function,
  },
  methods: {
    toggleDropDown: function () {
      this.open(this.id);
    },
  },
};
</script>
