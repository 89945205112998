import ApiService from "../api.loan.service";

export const GET_ALL_BULK_REPAYMENT = "GET_ALL_BULK_REPAYMENT";
export const setBulkRepayment = "setBulkRepayment";

export const SINGLE_BULK_REPAYMENT_DETAILS = "SINGLE_BULK_REPAYMENT_DETAILS";
export const setSingleBulkDetails = "setSingleBulkDetails";

export const GET_LOADING_DATA = "GET_LOADING_DATA";
export const setLoading = "setLoading";

export const bulkRepaymentErrors = "bulkRepaymentErrors";

const state = {
  allBulkRepayments: [],
  errors: [],
  singleBulkRepaymentDetails: [],
  loading: true,
};

const getters = {
  getBulkRepayment: (state) => state.allBulkRepayments,
  getSingleBulkDetails: (state) => state.singleBulkRepaymentDetails,
  // getLoading: (state) => state.loading,
};

const mutations = {
  setBulkRepayment: (state, payload) => (state.allBulkRepayments = payload),
  setSingleBulkDetails: (state, payload) =>
    (state.singleBulkRepaymentDetails = payload),
  setLoading: (state, payload) => (state.loading = payload),
  bulkRepaymentErrors: (state, payload) => (state.errors = payload),
};

const actions = {
  GET_ALL_BULK_REPAYMENT({ commit }, { pageindex, pagesize }) {
    commit(setLoading, true);
    commit(setBulkRepayment, { data: [] });
    ApiService.get(
      `RepaymentBatchUpload/getAllRepaymentBatchReport/${pageindex}/${pagesize}`
    )
      .then((response) => {
        commit(setBulkRepayment, response.data);
        commit(setLoading, false);
      })
      .catch((error) => {
        commit(bulkRepaymentErrors, error.message);
        commit(setLoading, false);
      });
  },

  SINGLE_BULK_REPAYMENT_DETAILS({ commit }, { batchKey }) {
    ApiService.get(
      `RepaymentBatchUpload/getAllRepaymentBatchProcessViewByBatchKey/${batchKey}`
    )
      .then((response) => {
        commit(setSingleBulkDetails, response.data);
      })
      .catch((error) => {
        commit(bulkRepaymentErrors, error.message);
      });
  },
};

export default { state, mutations, actions, getters };
