import GeneralApiService from "@/core/services/general.service";

// mutation types
export const GET_ALL_DISBURSEMENT = "GET_ALL_DISBURSEMENT";
export const setDisbursement = "setDisbursement";

export const GET_ALL_DISBURSEMENT_EXPORTS = "GET_ALL_DISBURSEMENT_EXPORTS";
export const setDisbursementExports = "setDisbursementExports";

export const setPagination = "setPagination";

export const CANCEL_DISBURSEMENT = "CANCEL_DISBURSEMENT";
export const setCancelDisbursement = "setCancelDisbursement";
export const RETRY_DISBURSEMENT = "RETRY_DISBURSEMENT";
export const setRetryDisbursement = "setRetryDisbursement";
export const ACTIVATE_DISBURSEMENT = "ACTIVATE_DISBURSEMENT";
export const setActivateDisbursement = "setActivateDisbursement";
export const setManualdisbursement = "setManualdisbursement";
export const APPROVE_MANUAL_DISBURSEMENT = "APPROVE_MANUAL_DISBURSEMENT";

export const disbursementErrors = "disbursementErrors";

const state = {
  allDisbursements: [],
  allDisbursementsExports: [],
  errors: [],
  pagination: "",
  disbursementCancelStatus: "",
  retryDisbursementStatus: "",
  activatedDisbursementStatus: "",
};

const getters = {
  getDisbursement: (state) => state.allDisbursements,
  getDisbursementExports: (state) => state.allDisbursementsExports,
  disbursePagination: (state) => state.pagination,
};

const mutations = {
  setDisbursement: (state, payload) => (state.allDisbursements = payload),
  setDisbursementExports: (state, payload) =>
    (state.allDisbursementsExports = payload),
  setCancelDisbursement: (state, params) =>
    (state.disbursementCancelStatus = params),
  setRetryDisbursement: (state, params) =>
    (state.retryDisbursementStatus = params),
  setActivateDisbursement: (state, params) =>
    (state.activatedDisbursementStatus = params),
  disbursementErrors: (state, payload) => (state.errors = payload),
  setPagination: (state, payload) => (state.pagination = payload),
};

const actions = {
  GET_ALL_DISBURSEMENT(
    { commit },
    {
      pageIndex,
      pageSize,
      disbursementMethod,
      filter,
      filters,
      filterCategory,
      isFromFilter,
    }
  ) {
    if (isFromFilter) {
      GeneralApiService.post(`GeneralFilter/ApplyFilter`, {
        filters,
        filterCategory,
        pageIndex,
        pageSize,
      })
        .then((res) => {
          commit(setPagination, res.data);
          commit(setDisbursement, res.data.data);
        })
        .catch((error) => {
          commit(disbursementErrors, error.message);
        });
    } else {
      GeneralApiService.get(
        `DisbursementManager/AllDisbursement?pageIndex=${pageIndex}&pageSize=${pageSize}&filterBy=${filter}&disbursementmethod=${disbursementMethod}`
      )
        .then((res) => {
          commit(setPagination, res.data.data);
          commit(setDisbursement, res.data.data.items);
          commit(setDisbursementExports, res.data.data);
        })
        .catch((error) => {
          commit(disbursementErrors, error.message);
        });
    }
  },
  GET_ALL_DISBURSEMENT_EXPORTS(
    { commit },
    { pageIndex, pageSize, filters, filterCategory }
  ) {
    GeneralApiService.post(`GeneralFilter/ApplyFilter`, {
      filters,
      filterCategory,
      pageIndex,
      pageSize,
    })
      .then((res) => {
        commit(setPagination, res.data);
        commit(setDisbursementExports, res.data);
      })
      .catch((error) => {
        commit(disbursementErrors, error.message);
      });
  },

  RETRY_DISBURSEMENT({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GeneralApiService.post(
        "DisbursementManager/RetryFailedDisbursement",
        payload
      )
        .then((data) => {
          commit(setRetryDisbursement, data.message);
          resolve(true);
        })
        .catch((error) => {
          commit(disbursementErrors, error.message);
          reject(error);
        });
    });
  },

  CANCEL_DISBURSEMENT({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GeneralApiService.post(
        "DisbursementManager/CancelPendingDisbursement",
        payload
      )
        .then((data) => {
          commit(setCancelDisbursement, data.message);
          resolve(true);
        })
        .catch((error) => {
          commit(disbursementErrors, error.message);
          reject(error);
        });
    });
  },

  ACTIVATE_DISBURSEMENT({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GeneralApiService.post(
        "DisbursementManager/Activatedisbursement",
        payload
      )
        .then((data) => {
          commit(setActivateDisbursement, data.message);
          resolve(true);
        })
        .catch((error) => {
          commit(disbursementErrors, error.message);
          reject(error);
        });
    });
  },

  APPROVE_MANUAL_DISBURSEMENT({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GeneralApiService.put("DisbursementManager/ApproveManual", payload)
        .then((data) => {
          commit(setManualdisbursement, data.message);
          resolve(true);
        })
        .catch((error) => {
          commit(disbursementErrors, error.message);
          reject(error);
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
